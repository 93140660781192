<template lang="pug">
div
  van-row(type="flex" justify="center")
    van-col
      h3 {{companyName}}
  van-form(@submit='onSubmit')
    van-field(v-model='mobile', name='手机号', label='手机号', placeholder='手机号', :rules="[{ required: true, message: '请填写手机号' }]")
    van-field(v-model='code', name='验证码', label='验证码', placeholder='验证码', :rules="[{ required: true, message: '请填写验证码' }]")
      template(#button)
        van-button(size='small', type='default' @click="sendCode" :disabled='sendState')  {{verify}}
    van-field(v-model='newpass' type='password' name='设置密码', label='设置密码', placeholder='设置密码', :rules="[{ required: true, message: '请填写设置密码' }]")
    van-field(v-model='renewpass' type='password' name='确认密码', label='确认密码', placeholder='确认密码', :rules="[{ required: true, message: '请填写确认密码' }]")
    van-field(v-model='name', name='真实姓名', label='真实姓名', placeholder='真实姓名', :rules="[{ required: true, message: '请填写真实姓名' }]")
    van-field(v-model='companyName', name='服务公司', label='服务公司', placeholder='服务公司' disabled)
    van-field(readonly clickable name='picker', :value='store.st_name', label='选择门店', placeholder='点击选择门店', @click='showPicker = true')
    van-popup(v-model='showPicker', position='bottom')
      van-picker(
        show-toolbar
       :columns='columns'
       @confirm='onConfirm'
       @cancel='showPicker = false')
    van-row.row
      van-button.col(type='info' native-type='submit' block round  :disabled='commitState') 注册

</template>

<script>
let startTime = 60
export default {
  data() {
    return {
      commitState: false,
      sendState: false,
      mobile: '',
      code: '',
      newpass: '',
      renewpass: '',
      name: '',
      companyName: '北京嘉利信得',
      stores: [], // 门店列表
      store: {}, // 选中的门店信息
      columns: [], // 门店列表选项,
      showPicker: false,
      verifyState: false,
      verify: '获取动态密码'
    }
  },
  computed:{
    comId(){
      return this.$store.state.routeInfo.comId
    }
  },
  watch: {
    comId: {
      handler() {
        this.onLoad()
       }
    },
  },
  methods: {
    onLoad() {
      if(this.comId != 0) {
        this.companyName = this.$store.state.companyInfo.com_name
        // console.log(this.comId)
        this.getComStore()
      }
    },
    // 获取门店列表
    async getComStore() {
      let res = await this.$api.GetCompanyStore(this.$store.state.routeInfo.comId)
      this.stores = res.data.items
      this.columns = this.stores.map(({st_name})=> {return st_name})
    },
    async onSubmit() {
      if(this.newpass != this.renewpass){
        this.$toast('两次密码输入不一致')
      } else if([...this.renewpass].length < 6) {
        this.$toast('密码长度不能少于6位')
      } else {
        let params = {
          code:	this.code,
          com_id_poi_companys: this.comId,
          designer_type:	"seller",
          designer_url:	"",
          mobile:	this.mobile,
          password:	this.newpass,
          st_id:	this.store.id,
          ui_name:	this.store.st_name
        }
        try {
          this.commitState = true
          await this.$api.Register(params)
          this.$toast('注册成功')
          this.$router.go(-1)
        } catch (error) {
          this.$toast('找回失败' + error)
        }
      }
      this.commitState = false
    },
    async sendCode() {
      if (!this.verifyState) {
        this.sendState = true
        try {
          let params = {
            type: 'web',
            mobile: this.mobile
          }
          await this.$api.RequestSmsCode(params)
          this.verifyState = true
          this.countdowntime()
          this.$toast('验证码发送成功!')
        } catch (e) {
          this.$toast(e.response.data.message)
        }
        this.sendState = false
      }
    },
    countdowntime() {
      let time = setTimeout(this.countdowntime, 1000)
      if (startTime === 0) {
        clearTimeout(time)
        this.verify = '获取动态密码'
        startTime = 60
        this.verifyState = false
        return false
      } else {
        startTime--
      }
      this.verify = startTime + 's后重新获取'
    },
    onConfirm(value) {
      this.store = this.stores.find(store => {
        return store.st_name === value
      })
      this.showPicker = false
    }
  },
  async activated() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      }
    })
    this.onLoad()
  }
}
</script>

<style lang="less" scoped>
.row {
  margin: 0px 10px;
}
.col {
  margin: 10px 0px;
}
</style>